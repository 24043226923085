<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" right>
    <template #button-content>
      <span v-if="selectedAccount" class="mr-50 d-none d-sm-inline-block">{{ selectedAccount.nickname }}</span>
      <span v-if="!selectedAccount" class="mr-50 d-none d-sm-inline-block">Selecione uma conta...</span>
      <font-awesome-icon :icon="['fas', 'cog']" fixed-width :class="{ 'text-danger':!selectedAccount }" />
    </template>
    <b-dropdown-item v-for="account in accounts" :key="account.seller_id" @click="changeSelectedAccount(account)" :active="selectedAccount && account.seller_id === selectedAccount.seller_id">
      <span class="ml-50">{{ account.nickname }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  mounted() {
    this.$store.dispatch('authorizations/refreshAuthorizations')
    if (this.$store.state.authorizations.accounts.length > 0) {
      this.changeSelectedAccount(this.$store.state.authorizations.accounts[0])
    }
  },
  computed: {
    accounts() {
      return this.$store.state.authorizations.accounts
    },
    selectedAccount() {
      return this.$store.state.authorizations.selectedAccount
    },
  },
  methods: {
    changeSelectedAccount(account) {
      this.$store.commit('authorizations/CHANGE_SELECTED_ACCOUNT', account.seller_id)
      this.$emit('change', account)
    },
  },
}
</script>

<style>

</style>

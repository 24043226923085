<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <font-awesome-icon :icon="['fas', 'bars']" size="2x" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!--<bookmarks />-->
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <account-selector />
      <locale />
      <dark-Toggler />
      <!--<search-bar />-->
      <!--<cart-dropdown />-->
      <!--<notification-dropdown />-->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
// import Bookmarks from '@core/layouts/components/app-navbar/components/Bookmarks.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
// import CartDropdown from '@core/layouts/components/app-navbar/components/CartDropdown.vue'
import AccountSelector from './components/SimpleAccountSelector.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import Locale from './components/Locale.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    Locale,
    DarkToggler,
    // SearchBar,
    // CartDropdown,
    AccountSelector,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },

}
</script>

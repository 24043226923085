import store from '@/store/index'

export default {
  methods: {
    colorVariant(prefix, variant) {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line
        return prefix + 'secondary'
      }
      // eslint-disable-next-line
      return prefix + (variant || 'primary')
    },
  },
}

<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar size="40" :src="userData.avatar" variant="light-primary" badge class="badge-minimal"
        badge-variant="success">
        <font-awesome-icon v-if="!userData.fullName" :icon="['far', 'user']" size="2x"/>
      </b-avatar>
    </template>

    <b-dropdown-item :to="{ name: 'accounts'}" link-class="d-flex align-items-center">
      <font-awesome-icon :icon="['fas', 'network-wired']" size="2x" class="mr-50" />
      <span>{{ $t('navigation.navbar.user_dropdown.accounts') }}</span>
    </b-dropdown-item>
    <!--
    <b-dropdown-item :to="{ name: 'system-profile'}" link-class="d-flex align-items-center">
      <font-awesome-icon :icon="['far', 'user']" size="2x" class="mr-50" />
      <span>{{ $t('navigation.navbar.user_dropdown.profile') }}</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-email' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="MailIcon" class="mr-50" />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-todo' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-chat' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item :to="{ name: 'pages-pricing' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Pricing</span>
    </b-dropdown-item>
    -->
    <b-dropdown-divider />

    <b-dropdown-item :to="{ name: 'system-settings' }" link-class="d-flex align-items-center">
      <font-awesome-icon :icon="['fas', 'cog']" size="2x" class="mr-50" />
      <span>{{ $t('navigation.navbar.user_dropdown.settings') }}</span>
    </b-dropdown-item>
    <!--
    <b-dropdown-item :to="{ name: 'system-faq' }" link-class="d-flex align-items-center">
      <font-awesome-icon :icon="['fas', 'question']" size="2x" class="mr-50" />
      <span>{{ $t('navigation.navbar.user_dropdown.help_support') }}</span>
    </b-dropdown-item>
    -->
    <b-dropdown-item :to="{ name: 'system-about' }" link-class="d-flex align-items-center">
      <font-awesome-icon :icon="['fas', 'info-circle']" size="2x" class="mr-50" />
      <span>{{ $t('navigation.navbar.user_dropdown.about') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item :to="{ name: 'auth-logout' }" link-class="d-flex align-items-center">
      <font-awesome-icon :icon="['fas', 'sign-out-alt']" size="2x" class="mr-50" />
      <span>{{ $t('navigation.navbar.user_dropdown.logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import useJwt from '@core-md/services/auth/useJwt'
import Crypt from '@core-md/utils/crypt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: Crypt.decrypt(useJwt.getUserData()),
      avatarText,
    }
  },
  mounted() {
    this.userData.role = 'admin'
    this.userData.avatar = '@/assets/images/avatars/13-small.png'
  },
}
</script>
